@import '../../../styles/variables.scss';

.gift {
    border-radius: 0.5rem;
    padding: 15px;
    color: $cs-green;

    &.on-modal {
        background-color: transparent;
    }

    .gift-sender-photo img {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        background-color: $cs-light;
    }

    .gift-sender-name {
        font-weight: bold;
        text-transform: uppercase;
    }

    .gift-msg {
        font-weight: 500;
    }

    .gift-comment {
        font-weight: 500;
        font-size: 1.1rem;

        .icon {
            color: $cs-green !important;
        }
    } 

    .bottom {
        padding: 0.5rem;
    }
}