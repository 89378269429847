@import '../../styles/variables.scss';
.auth {
    background-color: $cs-grey;
    border-radius: 0.5rem;
    padding: 15px;
    margin-bottom: 15px;
    @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
        border-radius: 0;
    }
    .switch {
        a {
            margin-left: 5px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    .social-separator {
        hr {
            width: 80px;
            background-color: $cs-white;
            margin: 0 10px;
        }
        span {
            font-weight: 500;
        }
    }
    .social-list {
        button {
            margin-bottom: 1rem; 
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}