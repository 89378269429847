@import '../../../styles/variables.scss';

@mixin renderTextStyle($font-weight, $font-size, $max-lines, $using-max-height: false, $line-height: $line-height-base * $font-size) {
    font-weight: $font-weight;
    font-size: $font-size;

    @if $using-max-height {

        max-height: $max-lines * $line-height;
    }

    @else {

        height: $max-lines * $line-height;
    }
}

@keyframes loading-bg {
    to {
        background-position:
            30rem 0;
    }
}

.product-list-item {
    background-color: $cs-white;
    border-radius: $border-radius;
    border: $border-width solid transparent;
    margin-bottom: 1rem;
    position: relative;
    font-size: 0.9rem;
    color: $cs-green !important;
    text-decoration: none !important;
    overflow: visible;

    .content {
        padding: 0.635rem;
    }

    .product-indicator {
        padding: 0.635rem;
        margin: -$border-width;
        border: $border-width solid transparent;
        border-bottom: none;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;

        .date {
            font-size: 0.75rem;
        }

        .indicator-icon {
            font-size: 1.5rem;
        }

        &.gift-sent-to {
            color: $cs-light;
            border-color: $secondary;
            background-color: $secondary;
        }

        &.pending-request {
            color: $cs-white;
            border-color: $primary;
            background-color: $primary;
        }
    }

    &:hover,
    &:active {
        border-color: $cs-green;
        background-color: darken($cs-white, 5%);

        .product-indicator {
            border-color: $cs-green;
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
        font-size: 0.95rem;
    }

    .photo {
        width: 100%;
        border-radius: $border-radius;

        .photo-img {
            border-radius: $border-radius;
        }
    }

    .product-title {
        @include renderTextStyle(700, 0.85rem, 1);

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            @include renderTextStyle(700, 0.88rem, 3);
            white-space: normal;
        }
    }

    .product-desc {
        @include renderTextStyle(400, 0.8rem, 3);
        white-space: normal;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            @include renderTextStyle(400, 0.83rem, 3);
        }
    }

    .product-date {
        font-weight: 500;
        font-size: 0.75rem;

        &.min-height {
            min-height: 3.4rem;
        }

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            font-weight: 500;
            font-size: 0.85rem;

            &.min-height {
                min-height: 0;
            }
        }
    }

    .state-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        min-height: 1.6rem;
    }

    .updated-time {
        text-align: right;
        width: 100%;
        font-style: italic;
        font-size: 0.6rem;
        margin-bottom: -$card-spacer-y / 2;
        font-weight: 500;
    }

    &.horizontal {
        .product-desc {
            @include renderTextStyle(400, 0.8rem, 4, true);
        }
    }

    &.loading {
        background-color: $cs-white;
        border-color: $cs-white;
        overflow: hidden;


        &:hover {
            background-color: $cs-white;
            border-color: transparent !important;
        }

        &::after {
            display: block;
            content: '';
            position: absolute;
        }

        .photo {
            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
                border-radius: $border-radius;
                background-color: darken($color: $cs-white, $amount: 40%);
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;
            }
        }

        .bottom-container {
            height: 2.6rem;

            @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                height: 1.7rem;
            }
        }

        .product-title {
            height: auto;

            &::after {
                content: "";
                margin-top: 0.3rem;
                height: 0.8rem;
                width: 90%;
                display: block;
                background-color: darken($color: $cs-white, $amount: 40%);
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;

                @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                    float: right;
                    height: 0.83rem;
                    animation-delay: 1.7s;
                    background-position: 10% 0;
                }
            }
        }

        .product-desc {

            &::after {
                content: "";
                margin-top: 0.5rem;
                height: 0.8rem;
                width: 75%;
                display: block;
                background-color: darken($color: $cs-white, $amount: 40%);
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;

                @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                    float: right;
                    margin-top: 0.5rem;
                    height: 0.85rem;
                    animation-delay: 1.7s;
                    background-position: -25% 0;
                }
            }
        }
    }
}