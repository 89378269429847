@import '../../../styles/variables.scss';

.statistic {
    background-color: $cs-white;
    border-radius: $border-radius;
    overflow: hidden;
    display: flex;
    margin-bottom: 1rem;
    height: 8rem;
    text-decoration: none !important;

    .indicator {
        background-color: $primary;
        flex: 0 0 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-width: $border-width;
        border-right-width: 0;

        .icon {
            font-size: 1.5rem;
            color: $cs-white !important;

            @media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) and (min-width: map-get($grid-breakpoints, "sm")) {
                font-size: 1.2rem;
            }
        }
    }

    .content {
        position: relative;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        border-style: solid;
        border-width: $border-width;
        border-color: $cs-white;
        border-left-width: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        &:hover,
        &:active {
            background-color: darken($cs-white, 5%);
        }

        .top {
            font-weight: bold;
            font-size: 2rem;
            line-height: 1;

            @media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) and (min-width: map-get($grid-breakpoints, "sm")) {
                font-size: 1.5rem;
            }
        }

        .desc {
            font-weight: bold;
            text-transform: uppercase;

            @media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) and (min-width: map-get($grid-breakpoints, "sm")) {
                font-size: 0.9rem;
            }
        }

        .addDesc {
            font-weight: 500;
            font-size: 0.9rem;

            @media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) and (min-width: map-get($grid-breakpoints, "sm")) {
                font-size: 0.8rem;
            }
        }

        .top-right-link {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.5rem 1rem;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.5rem 0.5rem 0.5rem 2rem;
            margin: 0;
            height: auto;
            width: auto;
            line-height: 1;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }

    &.primary {
        .indicator {
            background-color: $primary !important;
            border-color: $primary !important;
        }

        .content,
        .top-right-link {
            color: $primary !important;

            &:hover,
            &:active {
                border-color: $primary !important;
            }
        }
    }

    &.secondary {
        .indicator {
            background-color: $secondary !important;
            border-color: $secondary !important;
        }

        .content,
        .top-right-link {
            color: $secondary !important;

            &:hover,
            &:active {
                border-color: $secondary !important;
            }
        }
    }

    &.danger {
        .indicator {
            background-color: $danger !important;
            border-color: $danger !important;
        }

        .content,
        .top-right-link {
            color: $danger !important;

            &:hover,
            &:active {
                border-color: $danger !important;
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
        height: auto;
    }
}