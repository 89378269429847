@import '../../styles/variables.scss';

.modal {
    color: $cs-green;
    font-size: smaller;

    &.modal-hud-loading {
        .modal-dialog {
            margin: 1.75rem auto;
        }

        .modal-content {
            background-color: transparent;
            border: none;
            text-align: center;
        }

        .modal-body {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .msg:first-of-type {
                margin-top: 0.2rem;
            }
        }
    }

    &.modal-hud-alert {
        .modal-dialog {
            @media screen and (min-width: map-get($grid-breakpoints, "md") - 1) {
                max-width: 300px;
            }
        }

        .modal-body {
            text-align: center;
        }

        .modal-footer {
            background-color: $cs-grey;
        }
    }

    &.modal-hud-progress,
    &.modal-hud-toast {
        .modal-dialog {
            @media screen and (min-width: map-get($grid-breakpoints, "md") - 1) {
                max-width: 300px;
            }
        }

        .modal-content {
            background-color: transparent;
            border: none;
            text-align: center;
            border-radius: 0.5rem;
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .msg {
                margin-top: 0.5rem;
            }
        }
    }
}