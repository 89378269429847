@import '../../styles/variables.scss';
.home {
    .top {
        overflow: hidden;
        background-color: $cs-grey;
        position: relative;
        text-align: center;
        .content {
            min-height: 23rem;
            .title {
                font-size: 1.8rem;
                @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
                    font-size: 1.5rem;
                }
            }
            .description {
                font-size: 1.3rem;
            }
        }
        .top-bg {
            content: "";
            opacity: 0.3;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .section {
        .title {
            font-size: 1.2rem;
            @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                font-size: 1rem;
            }
        }
        .description {
            font-size: 0.9rem;
        }
    }
    .bottom {
        .description {
            font-size: 1.3rem;
        }
    }
}