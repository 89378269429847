@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/mixins/_buttons.scss';

.btn {
  text-transform: uppercase;

  &.btn-primary {
    color: $cs-white;

    &:hover {
      color: darken($cs-white, 15%);
    }
  }

  &.btn-icon-only {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;

    &.btn-sm {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.75rem;
    }
  }

  &.btn-icon-left {
    position: relative;

    .btn-icon {
      position: absolute;
      left: $input-btn-padding-x;
      top: -$input-border-width;
      bottom: 0;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-icon-svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.btn-facebook {
  @include button-variant(#4267b2, #4267b2);
}

.btn-twitter {
  @include button-variant(#1da1f2, #1da1f2);
}

.btn-google {
  @include button-variant(#ffffff, #ffffff);
}

.custom-checkbox {
  .custom-control-label {
    line-height: 1.5rem;
    padding-left: 0.3rem !important;
  }
}

.invalid-feedback {
  padding-left: $input-btn-padding-x;
  padding-right: $input-btn-padding-x;
  font-weight: 500;
  font-style: italic;
  color: $cs-light;

  &.invalid-feedback-light {
    color: $danger;
  }
}

.form-text.text-muted {
  padding-left: $input-btn-padding-x;
  padding-right: $input-btn-padding-x;
  font-weight: 500;
  font-style: italic;
  color: $cs-light;

  span {
    margin-left: 0.4rem;
  }

  &.text-muted-light {
    color: $danger;
  }
}

.form-group {
  position: relative;
}

.form-control {
  position: relative;

  &:disabled,
  &[readonly] {
    opacity: 0.8;
  }
}

input[type='text'].form-control,
textarea.form-control {
  &::-moz-selection {
    background: darken($color: $cs-green, $amount: 0.1);
    color: $cs-light;
  }

  &::selection {
    background: darken($color: $cs-green, $amount: 0.1);
    color: $cs-light;
  }
}

textarea.form-control {
  min-height: 7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.font-weight-medium {
  font-weight: 500;
}

.nav-tabs .nav-link {
  border-width: 0 0 4px 0;
  font-weight: 500;
}

// .container.container-sm-fluid {
//     @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
//         padding-left: 0;
//         padding-right: 0;
//     }
// }
.input-group {
  input {
    &.appended-right {
      border-right-width: 0;
      padding-right: 0.5rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(175, 157, 131, 0.25);
      }
    }

    &.disable-keep-normal-input {
      opacity: 1 !important;
      background-color: $input-bg;
    }
  }

  .input-group-append {
    &.appended-right {
      background-color: $input-bg;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border: $input-border-width solid $input-border-color;
      border-left-width: 0;
      padding-left: 0.2rem;

      .btn {
        border-top-left-radius: $input-border-radius !important;
        border-bottom-left-radius: $input-border-radius !important;
        margin-right: -$input-border-width;
        margin-top: -$input-border-width;
        margin-bottom: -$input-border-width;
      }
    }
  }
}

.form-control {
  &.input-light {
    background-color: $cs-light;
    color: $cs-grey;
  }

  &input[type='text'] {
  }
}

.modal-content {
  overflow: hidden;
}

.modal-footer {
  background-color: $cs-grey;
}

.modal-header {
  height: 2.1rem;
  background-color: $cs-grey;
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
  border-bottom-color: $cs-grey;

  .modal-title {
    font-weight: bold;
    line-height: 2rem;
  }
}

.fade {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.w-sm-auto {
  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    width: auto !important;
  }
}

.border-thin {
  border-width: $border-width / 2 !important;
}

.custom-select {
  font-size: 1rem;
  font-weight: 400;

  background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size;
  background-color: $cs-black;

  @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: $custom-select-indicator no-repeat right ($custom-select-padding-x - 0.5) center /
      $custom-select-bg-size;
    background-color: $cs-black;
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.65rem;
    background: $custom-select-indicator no-repeat right ($custom-select-padding-x - 1) center / $custom-select-bg-size;
    background-color: $cs-black;
  }

  &.center {
    text-align-last: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.form-group label {
  padding-left: 1.25rem;
  margin-bottom: 0.2rem;
  font-weight: 500;
}

.table {
  margin-bottom: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-grey {
  background-color: $cs-grey;
}
