@import '../../../../../styles/variables.scss';

.gift-sending {
    #tag-value-container {
        position: relative;
    }

    .users-searching-indicator {
        position: absolute;
        right: 0;
        top: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 1.5rem !important;
            height: 1.5rem !important;
            margin-right: 0.5rem !important;
        }

        &.with-label {
            top: -0.3rem;
        }
    }
}

.user-tag-popover {
    min-width: 20rem;

    .popover-body {
        color: $cs-green;
        padding: 0;
        overflow: hidden;
        border-radius: $border-radius;

        .suggestions .suggestion {
            border-left: none;
            border-right: none;
            text-align: left;
            border-radius: 0 !important;
            cursor: pointer;
            display: flex;
            color: $cs-green;
            outline: none;

            &:first-child {
                border-top-color: transparent;
            }

            &:last-child {
                border-bottom-color: transparent;
            }

            &:hover,
            &:active {
                background-color: darken($cs-white, 5%);
            }
        }
    }
}