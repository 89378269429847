@import '../../../styles//variables.scss';

.user-nav {
    .nav-tabs {
        .nav-link {
            @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
                display: none;

                &.active {
                    display: block;
                }
            }
        }
    }

    margin-bottom: 1rem;

    .nav-pills {
        flex-wrap: nowrap;
    }

    .nav-icon {
        margin-right: 0.5rem;
    }

    .nav-justified .nav-item {
        text-align: left;
        flex: none;

    }

    @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
        background-color: $cs-grey;
        margin-bottom: 0;

        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;

        &.container {
            padding-left: 0;
            padding-right: 0;
        }

        .nav-justified .nav-item {
            text-align: center;
            flex-basis: 20%;
            flex-grow: 1;
            overflow: hidden;

            .nav-icon {
                margin-right: 0;
                margin-bottom: 0.5rem;
                font-size: 1.4rem !important;
            }

            .nav-link {
                padding: 0.5rem;
                border-radius: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
        font-size: 0.65rem;
    }
}