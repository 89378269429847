@import '../../../../styles/variables.scss';


.owner-info {
    border-radius: $border-radius; 

    .owner-pic {
        border: $border-width solid rgba($cs-light, 0.5);
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.9rem;
        margin-right: 0.5rem;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .owner-details {
        font-weight: 500;
        font-size: 1rem;  
    }
}