@import '../../../styles/variables.scss';

.product-action-container {
    position: relative;

    .action-icon {
        font-size: 2.5rem;
    }

    .action-title {
        font-weight: bold;
        font-size: 0.95rem;
    }

    .action-desc {
        font-weight: 500;
        font-size: 0.9rem;
    }

    .action-close {
        position: absolute;
        top: 0.6rem;
        right: 0.6rem;
    }
}