@import '../../styles/variables.scss';
.dashboard {
    .heading {
        color: $cs-green;
        .heading-link-btn {
            height: auto;
            text-transform: none;
            padding: 0;
            line-height: 1;
        }
        .title {
            font-weight: bold;
            font-size: 1.3rem;
            @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
                font-size: 1rem;
            }
        }
    }
    .product-top-stat {
        .top-pos {
            font-weight: bold;
            font-size: 2.0rem;
        }
    }
}

.money-stat-popover {
    font-size: 0.9rem;
    min-width: 13rem;
    color: $cs-green;
    .section {
        margin-bottom: 0.5rem;
        .title {
            font-weight: bold;
            border-bottom: 1px solid $cs-green;
            margin-bottom: 0.5rem;
        }
        .money {
            display: flex;
            justify-content: space-between;
            .currency {
                font-weight: 500;
            }
        }
        &:last-of-type {
            margin-bottom: 0
        }
    }
    .currency-hint {
        font-size: 0.7rem;
    }
    .currency-option {
        font-size: 0.7rem;
        color: $cs-green;
        background: transparent;
        border: none;
        padding: 0;
        margin-left: 0.5rem;
        outline: none;
        cursor: pointer;
        &.selected {
            font-weight: bold;
            border-bottom: 1px solid $cs-green;
        }
    }
}