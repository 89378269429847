@import '../../../styles/variables.scss';

.requests-wrapper {
    .request-info {
        cursor: pointer;
        border-radius: 1.25rem;

        margin-top: 1rem; 

        &:first-child {
            margin-top: 0;
        } 

        .request-info-pic {
            border: $border-width solid rgba($cs-light, 0.5);
            margin-right: 0.5rem;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .request-content {
            min-height: 2.5rem;
            border-radius: 1.25rem;
            background-color: darken($cs-white, 5%);
            padding: 0.71rem 1rem;
            line-height: 1.2;
            font-size: 0.9rem;
        }

        .date-request {
            font-size: 0.6rem;
            padding-left: 4rem;
        }

        &.is-removing {
            opacity: 0.4;
        }
    }
}