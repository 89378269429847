@import '../../styles//variables.scss';
img.user-photo,
div.user-photo {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 1.25rem;
    background-color: $cs-light;
    display: flex;
    justify-content: center;
    align-items: center;
    .user-photo-svg-fa {
        color: $cs-green;
    }
}