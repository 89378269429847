@import '../../../styles//variables.scss';

.photo-uploader {
    .input-file {
        margin: 0.5rem 0;
        width: 5rem;
        height: 5rem;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        cursor: pointer;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .input-file+label {
        margin: 0.5rem 0;
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $cs-light;
        border-radius: $border-radius;
        color: $cs-green;
        cursor: pointer;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .input-file:focus+label,
    .input-file:hover+label {
        background-color: $cs-green;
        color: $cs-light;
        outline: 0;
        box-shadow: 0 0 0 $input-border-width rgba($primary, .25);
    }

    .input-file+label * {
        pointer-events: none;
    }

    .photo-upload-item {
        margin: 0.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: $border-radius;
        overflow: hidden;
        position: relative;

        .thumnail-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5rem;
            height: 5rem;
            border-radius: $border-radius;
            overflow: hidden;
            background-color: $cs-light;
        }

        .video-duration {
            font-size: 0.5rem;
            text-align: right;
            color: $cs-white;
            font-weight: bold;
            position: absolute;
            right: $border-radius / 2;
            bottom: $border-radius / 2;
        }

        .photo-remove {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            background-color: rgba($cs-black, .6);
            cursor: pointer;
            color: $cs-green;

            @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                display: none !important;
            }

            cursor: pointer;
        }

        &:hover {
            outline: 0;
            box-shadow: 0 0 0 $input-border-width rgba($primary, .25);

            .photo-remove {
                display: block;
            }
        }

        .photo-remove-bottom {
            width: 100%;
            height: 2rem;
            border: 0;
            background: $cs-green;
            border-radius: $border-radius;
            color: $cs-white;
            outline: none;
            cursor: pointer;
        }
    }

    .drop-hint-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($cs-green, .8);
        margin: 0 0.5rem;
        border: $border-width dashed $cs-light;
        border-radius: $border-radius;
        display: none;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1.5rem;
        color: $cs-light;

        &.is-dragover {
            display: flex;
        }
    }
}