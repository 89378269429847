$cs-grey: #1a1a1a;
$cs-green: #af9d83;
$cs-brown: #5f5f5f;
$cs-light: #d8d8d8;
$cs-white: #ffffff;
$cs-black: #000000;
$cs-red: #dc3545;
$body-bg: #000;
$body-color: $cs-white;
$font-family-sans-serif: 'Montserrat', sans-serif;
$link-color: $cs-green;
$primary: $cs-green;
$secondary: $cs-brown;
$danger: $cs-red;
$light: $cs-light;
$footer-height: 10.375rem;
$footer-xs-height: 27rem;
$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
  ),
  $grid-breakpoints
);
$enable-rounded: true;
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 720px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
  ),
  $container-max-widths
);
$line-height-base: 1.5;
$font-size-base: 1rem;
$border-width: 2px;
$border-radius: 0.5rem;
$input-btn-line-height: 2.3rem;
$input-btn-padding-y: 0;
$input-btn-padding-x: 1.15rem;
$input-btn-font-size: 1rem;
$input-height-inner: (($font-size-base * 2.3) + ($input-btn-padding-y * 2)) !default;
$btn-border-radius: 1.25rem;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-font-weight: 500;
$input-line-height: 2.3;
$input-color: $cs-white;
$input-border-color: $cs-green;
$input-placeholder-color: $cs-green;
$input-border-width: 2px;
$input-border-radius: 1.25rem;
$input-bg: $cs-black;
$input-disabled-bg: lighten($cs-black, 20%);
$input-focus-border-color: $input-border-color;
$custom-checkbox-indicator-indeterminate-bg: $cs-green;
$custom-checkbox-indicator-indeterminate-color: $cs-green;
$custom-select-border-radius: $input-border-radius;
$custom-select-padding-y: $input-btn-padding-y;
$custom-select-padding-x: $input-btn-padding-x;
$custom-select-indicator-color: $cs-green;
$custom-select-indicator-padding: 1rem;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-select-bg-size: 8px 10px !default;
$form-feedback-font-size: 90%;
$modal-content-border-color: $cs-green;
$modal-content-bg: $cs-white;
$modal-content-border-width: 2px;
$modal-content-border-width: 2px;
$modal-inner-padding: 0.5rem;
$zindex-modal: 1050;
$nav-tabs-link-active-color: $cs-green;
$nav-tabs-border-width: 2px;
$nav-tabs-border-radius: 0;
$nav-tabs-border-color: lighten($cs-green, 10%);
$nav-tabs-link-active-border-color: transparent transparent $cs-green transparent;
$nav-tabs-link-hover-border-color: transparent transparent $cs-green transparent;
$nav-tabs-link-active-color: $cs-white;
$table-cell-padding: 0.5rem;
$table-cell-padding-sm: 0.5rem;
$modal-fade-transform: none;
$modal-show-transform: none;
$modal-transition: none;
$modal-header-padding: 0 0.5rem;
$modal-content-border-radius: $border-radius;
$alert-border-radius: $border-radius;
$card-spacer-y: 0.635rem;
$card-spacer-x: 0.635rem;
$modal-backdrop-opacity: 0.8;
