@import '../../styles/variables.scss';

.quotediable {
    margin-bottom: 1rem;

    .quote-container {
        text-align: center;
        padding: 1rem 3rem;
        position: relative;

        .icon {
            font-size: 1.5rem;
            position: absolute;
            color: $secondary;

            &.q-open {
                top: 0;
                left: 1.2rem;
            }

            &.q-close {
                bottom: 0;
                right: 1.2rem;
            }
        }

        .comment {
            font-weight: 500;
            font-size: 1.1rem;
            font-style: italic;
        }

    }
}