@import '../../../../styles/variables.scss';
.product-list-item {
    .date-range-label-separator {
        height: 0;
        border-bottom: 1px solid $cs-green;
    }
    .damage-date {
        font-weight: $btn-font-weight;
        text-align: center;
        vertical-align: middle;
        border: $input-border-width solid transparent;
        padding: 0.2rem $btn-padding-x;
        font-size: $btn-font-size * 0.85;
        line-height: $btn-line-height / 2 - 0.1rem;
        color: $cs-green;
        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}