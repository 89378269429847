@import '../../styles/variables.scss';

$cs-bottom-nav-height: 4rem;

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footer-height;
    background-color: $cs-grey;
    padding: 15px 0;
    font-size: smaller;

    .logo {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 0 0.5rem 0;
    }

    h4 {
        font-size: 1rem;
        color: $cs-green;
    }

    @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
        height: $footer-xs-height + $cs-bottom-nav-height;
        padding-bottom: $cs-bottom-nav-height;
    }
}

#root {
    padding-bottom: $footer-height;

    @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
        padding-bottom: $footer-xs-height + $cs-bottom-nav-height;
    }
}