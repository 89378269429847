@import '../../../styles/variables.scss';

.histories {
    .item-extend {
        border-color: $primary;
        background-color: $primary;
    }

    .item-buy {
        border-color: $primary;
        background-color: $primary;
    }

    .item-release {
        border-color: $primary;
        background-color: $primary;
    }

    .item-damage {
        border-color: $danger;
        background-color: $danger;
    }

    .item-gift {
        border-color: $secondary;
        background-color: $secondary;
    }

    .item-request {
        border-color: $secondary;
        background-color: $secondary;
    }

    .item-loading {
        background-color: $cs-white;
        border-color: $cs-white;
    }

    .hitories-heading {
        display: flex;

        .history-time-line {
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: -1.8rem;
            position: relative;

            .root-icon {
                color: $cs-white;
                width: 3rem;
                height: 3rem;
                border-radius: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .history-vline-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 0.3rem;

                .history-vline {
                    height: 3rem;
                    width: 0;
                    border-left-width: 2px;
                    background-color: transparent !important;

                    &.solid {
                        border-left-style: solid;
                    }

                    &.dotted {
                        border-left-style: dotted;
                    }
                }
            }
        }

        .root-lbl {
            font-weight: bold;
            height: 3rem;
            line-height: 3rem;
            margin-left: 1rem;
            flex: 1;
        }
    }
}