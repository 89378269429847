@import '../../styles/variables.scss';
$link-nav-desktop: $cs-green;
$link-nav-desktop-active: #ffffff;
.cs-navbar {
    margin-bottom: 1rem;
    background-color: $cs-grey;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05), inset 0 -1px 0 rgba(0, 0, 0, .1);
    padding: 0;
    img.logo {
        width: 2.5rem;
        height: 2.5rem;
        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            margin-left: .25rem;
        }
    }
    .navbar-toggler {
        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            padding: 0;
            margin: 0;
            width: 50px;
            height: 50px;
            border-radius: 0;
            outline: none;
            &:not(.collapsed) {
                background-color: $cs-brown;
            }
        }
        .svg-inline--fa {
            color: $cs-green;
        }
    }
    .navbar-collapse {
        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            background-color: $cs-brown;
        }
    }
    .navbar-nav {
        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            border-bottom: 2px solid $cs-green;
        }
        .nav-link {
            color: $link-nav-desktop;
            font-weight: 500;
            padding-right: 1rem;
            padding-left: 1rem;
            &:hover,
            &:active {
                color: darken($cs-green, 15%);
            }
            &.active {
                color: $link-nav-desktop-active;
            }
        }
        .nav-item {
            &.nav-devider {
                height: 2px;
                background-color: $cs-green;
            }
        }
        .btn-link {
            width: 100%;
            text-align: left;
            text-transform: capitalize;
            text-decoration: none;
            font-weight: 500;
            font-size: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .user-dropdown {
        .dropdown-menu {
            width: 100%;
            background-color: $cs-brown;
            .dropdown-item {
                cursor: pointer;
                color: $cs-green;
                font-weight: 500;
                outline: none;
                &:hover,
                &:active,
                &.active {
                    color: $cs-white;
                    background-color: darken($cs-brown, 10%)
                }
            }
            .dropdown-divider {
                border-top-color: $cs-green;
                border-top-width: 2px;
            }
        }
    }
    .user {
        border: 2px solid $cs-green;
        padding: 0 1rem;
        border-radius: 1.25rem;
        height: 2.5rem;
        min-width: 12rem;
        &.user-init {
            background-color: $cs-brown;
            border: none;
        }
        &.authenticated {
            width: auto;
            min-width: auto;
            padding-left: 0;
            padding-right: 0.5rem;
            border: none;
            max-width: 12rem;
            &.toggler {
                width: 2.5rem;
                padding: 0;
                margin: auto;
            }
        }
        a {
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0 0.25rem;
        }
        a.active {
            color: #ffffff;
        }
        img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 1.25rem;
            background-color: $cs-light; 
        }
        .display-name {
            margin: 0 0.3rem;
            font-size: 0.9rem;
            text-overflow: ellipsis;
            min-width: 0;
            overflow: hidden;
            flex: 1;
            white-space: nowrap;
        }
    }
}