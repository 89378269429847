@import '../../../styles/variables.scss';

.gift-vailable {
    position: absolute;

    &.on-modal {
        left: $border-width + 1;
        top: $border-width + 1;
        z-index: $zindex-modal + 1;
        display: flex;
        flex-direction: column;

        .btn:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    &.on-list {
        left: 0.635rem;
        top: 0.635rem;
        display: flex;
        flex-direction: row;
        padding: $border-width;
        margin: $border-width;
        background-color: rgba($cs-white, 0.3);
        border-radius: $input-border-radius;

        .btn:not(:last-child) {
            margin-right: 0.5rem;
        }

        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            .btn {
                width: 2rem;
                height: 2rem;
                font-size: 0.7rem;
                line-height: 1.3;
            }
        }
    }
}