@import '../../../../styles/variables.scss';

.chainsure-make-gift-modal {
    .gift-desc-btn {
        text-transform: none;
        text-decoration: underline;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 1rem;
        font-weight: 500;

        &.edit {
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 1.3;
        }
    }

    .quote-container {
        text-align: center;
        padding: 1rem 3rem;
        position: relative;

        .icon {
            font-size: 1.5rem;
            position: absolute;
            color: $secondary;

            &.q-open {
                top: 0;
                left: 1.2rem;
            }

            &.q-close {
                bottom: 0;
                right: 1.2rem;
            }
        }

        .comment {
            font-weight: 500;
            font-size: 1.1rem;
            font-style: italic;
        }

    }


    .gift-action {
        margin-top: 1.5rem;
    }
}