@import '../../../styles/variables.scss';

@keyframes loading-bg {
    to {
        background-position:
            30rem 0;
    }
}

.history-item {
    &.first {
        .history-time-line {
            .history-vline-container {
                .history-vline {
                    border-left-style: dashed;
                }

                bottom: -1rem - 1.1rem -2.0rem;
            }
        }
    }

    &.last {
        .history-time-line {
            .history-vline-container {
                .history-vline {
                    display: none;
                }
            }
        }
    }

    margin-top: 1rem;
    display: flex;

    .history-time-line {
        position: relative;

        .history-vline-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1.1rem 1.1rem 0 0.6rem;
            top: 1.8rem + 0.3rem;
            bottom: -1rem - 1.1rem + .3rem;
            left: 0;
            right: 0;

            .history-vline {
                width: 0;
                border-left-width: 2px;
                border-left-style: solid;
                background-color: transparent !important;
                flex: 1;
            }
        }
    }


    .history-indicator {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.9rem;
        margin: 1.1rem 1.1rem 0.6rem 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: $cs-white;
        font-size: 0.8rem;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .history-wrapper {
        flex: 1;

        .product-date {
            padding-left: $border-radius;
        }

        .user-photo {
            border: $border-width solid rgba($cs-light, 0.5);
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 0.9rem;
            margin-right: 0.5rem;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .card {
            border: none;

            .card-footer {
                border: none;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                text-align: right;
                color: $cs-white !important;

                a {
                    color: $cs-white !important;
                    text-decoration: none;
                    font-size: 0.8rem;

                    &:hover,
                    &:active {
                        font-weight: 500;
                    }
                }
            }
        }

        .history-content {
            .heading {
                display: flex;
            }

            .content {
                display: flex;
                align-items: center;
                min-height: 1.8rem;
            }

            .comment {
                margin-top: 0.5rem;
                font-weight: 500;
            }

            .media {
                margin-top: 0.5rem;
            }

            .sub-content {
                margin-top: 0.5rem;
                color: $cs-white;

                .card-body {
                    padding: $card-spacer-y / 2 $card-spacer-x / 2;
                }

                .comment {
                    margin-top: 0.2rem;
                }

                .heading {
                    align-items: center;
                }
            }
        }
    }

    &.end {
        .history-indicator {
            background-color: $cs-grey;

            .logo {
                width: 80%;
                height: 80%;
                border-radius: 1rem;
            }
        }
    }

    &.loading {
        .product-date {
            height: 1rem;
        }

        .history-indicator {
            &::after {
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                padding-bottom: 100%;
                background-color: darken($color: $cs-white, $amount: 40%);
                border-radius: 0.9rem;
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;
            }
        }

        .user-photo {
            border: none;

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
                background-color: darken($color: $cs-white, $amount: 40%);
                border-radius: 0.9rem;
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;
            }
        }

        .content {
            flex-grow: 1;

            &::after {
                content: "";
                display: block;
                width: 90%;
                height: 0.8rem;
                background-color: darken($color: $cs-white, $amount: 40%);
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-position: -5.835rem 0;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;
            }
        }

        .comment {

            &::after {
                content: "";
                display: block;
                width: 70%;
                height: 0.8rem;
                background-color: darken($color: $cs-white, $amount: 40%);
                background-repeat: no-repeat;
                background-size: 5rem 30rem;
                background-position: -2.3rem 0;
                background-image: linear-gradient(to right, transparent, darken($color: $cs-white, $amount: 30%), transparent);
                animation: loading-bg 1.2s infinite;
            }
        }
    }
}