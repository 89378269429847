@import "../../styles/variables.scss";

.showcase {
  > .row {
    border-radius: $border-radius;
    background-color: $cs-grey;
    position: relative;
    overflow: hidden;
    min-height: 50rem;
  }

  .parallelogram {
    width: 100%;
    position: absolute;
    top: 0.3rem;

    @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
      top: 8rem;
    }
  }

  .app-dashboard {
    width: 90%;

    @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
      width: 80%;
    }
  }

  .app-desc {
    margin-top: -2rem;
    text-align: center;

    .title {
      font-weight: bold;
      font-size: 1.9rem;
    }
    .desc {
      font-weight: 500;
      font-size: 1.2rem;
    }

    .links {
      .app-link {
        margin: 0.5rem;
        border: $border-width solid $cs-green;
        border-radius: $border-radius;
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
      margin-top: 0;

      .title {
        font-size: 1.5rem;
      }
      .desc {
        font-weight: 500;
        font-size: 1rem;
      }
      .links {
        .app-link {
          margin: 0.2rem;
        }
      }
    }
  }

  .screen {
    &:nth-child(3) {
      margin-top: 1rem !important;

      @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
        margin-top: 0.5rem !important;
      }
    }

    img {
      width: 100%;

      @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
        width: 80%;
      }
    }

    .screen-info {
      @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
        margin-top: -1rem;
      }
    }
  }
}
