@import '../../styles/variables.scss';

.owned-item.chainsure-list-item.loading {
    .bottom-container {
        height: 7.9rem;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            height: 3rem;
        }
    }
}