@import '../../styles/variables.scss';

.gift {
    color: $cs-green;
    background-color: $cs-grey;
    border-radius: 0.5rem;
    padding: 15px;

    .gift-sender-photo img {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        background-color: $cs-light;
    }

    .gift-sender-name {
        font-weight: bold;
        text-transform: uppercase;
    }

    .gift-msg {
        font-weight: 500;
    }

    .gift-comment {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .bottom {
        padding: 0.5rem;
    }
}