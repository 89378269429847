@import '../../../../styles/variables.scss';

.product-list-item {
    .date-range-label-separator {
        height: 0;
        border-bottom: 1px solid $cs-green;
    }

    .released-date {
        font-weight: $btn-font-weight;
        font-size: $btn-font-size * 0.85;
        line-height: $btn-line-height / 2 - 0.1rem;
        color: $cs-green;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .reviewing-buy-date {
        text-align: center;
        font-weight: $btn-font-weight;
        font-size: $btn-font-size * 0.85;
        line-height: $btn-line-height / 2 + 0.1rem;
        color: $cs-green;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .buy-again {
        .buy-again-lbl {
            font-size: 0.75rem;
        }

        .price-value {
            font-size: 1.4rem;
        }
    }
}

.chainsure-list-item.loading {
    .bottom-container {
        height: 7.1rem;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            height: 3rem;
        }
    }
}