@import '../../../styles/variables.scss';

.photo-gallery {
    .photo-gallery-media {
        position: relative;
        width: 6rem;
        height: 6rem;
        margin: 0 0.5rem 0.5rem 0;
        background-color: $cs-light;
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: $border-width solid transparent; 

        &:hover,
        &:active {
            border-color: darken($cs-green, 15%) !important;
        }

        .type-indicator {
            position: absolute;
            bottom: -$border-width;
            right: -$border-width;
            background: rgba(#000, 0.6);
            padding: 0.3rem 0.3rem 0.15rem;
            border-top-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            .svg-inline--fa {
                font-size: 1rem;
            }
        }
    }
}