@import '../../../../styles/variables.scss';

.product-list-item {
    .owner {
        .owner-pic {
            border: $border-width solid rgba($cs-light, 0.5);
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 0.9rem;
            margin-right: 0.5rem;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .owner-details {
            font-weight: bold;
            font-size: 0.8rem;
        }
    }
}