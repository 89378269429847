@import '../../styles/variables.scss';

.text-field-custom {
    .text-length {
        position: absolute;
        font-size: 0.7rem;
        font-weight: 500;
        right: 0.5rem;

        &.limited {
            color: $cs-red;
        }
    }
}