@import '../../../styles/variables.scss';

.modal-product-detail {
    color: $cs-green;

    .card {
        border: none;
    }

    .modal-content {
        border: none;
        background-color: transparent;
    }

    .modal-footer {
        background-color: transparent;
        border: none;
    }

    .photo {
        width: 100%;
        border-radius: $border-radius;
        border: $border-width solid $cs-green;
        overflow: hidden;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .product-title {
        font-weight: 700;
        font-size: 0.85rem;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            font-size: 0.88rem;
        }
    }

    .product-desc {
        font-weight: 400;
        font-size: 0.8rem;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            font-size: 0.83rem;
        }
    }

    .product-date {
        font-weight: 500;
        font-size: 0.75rem;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            font-size: 0.65rem;
        }
    }

    .state-desc {
        text-align: center;
        font-style: italic;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
}