 @import 'styles/bootstrap-custom.scss';
 .cs-text-white {
     color: #ffffff
 }

 main[role=main] {
     padding-top: 15px;
     padding-bottom: 15px;
     font-size: 0.9rem;
 }

 html,
 body {
     height: 100%;
     padding-right: 0 !important;
 }

 #root {
     position: relative;
     min-height: 100%;
 }