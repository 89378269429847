@import '../../styles/variables.scss';
.static-container {
    .static-block {
        background-color: $cs-grey;
        padding: 15px 0;
        border-radius: $border-radius;
        
        @media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
            border-radius: 0;
        }
    }
}