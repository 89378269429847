@import '../../styles/variables.scss';

.price {
    display: flex;

    .tag {
        padding-top: 0.1rem;
    }

    span {
        display: block;
        color: $cs-green;
        font-size: 2rem;
        line-height: 1;
        margin-left: 0.3rem;
        font-weight: 700;

        @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
            font-size: 1.8rem;
        }
    }

    &.amount-currency {
        flex-direction: row-reverse;

        span {
            margin-left: 0;
            margin-right: 0.3rem
        }
    }

    &.lg {
        .tag {
            padding-top: 0.3rem;
        }

        span {
            font-size: 2.3rem;

            @media screen and (max-width: map-get($grid-breakpoints, "sm") - 1) {
                font-size: 2rem;
            }
        }
    }

    &.free {
        font-size: 2rem;
    }
}